import './title.scss'

const Title = ({title}) => {
  return (
    <div className='main-container'>
      <span className='title'>{title}</span>
      <hr></hr>
    </div>
  )
}

export default Title;