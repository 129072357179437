import phone from '../../icons/phone.svg'
import email from '../../icons/email.svg'

const Contact = () => {
  return(
    <>
      <div className='flex business-details'>
            <img 
              src={phone}
              className='footer-icon'
              alt='phone'
            />
          <span>+61 400 997 296</span>
          </div>
          <div className='flex business-details'>
            <img 
            src={email}
              className='footer-icon'
              alt='email'
            />
          <span>susan@smcpheepsych.com</span>
          </div>
    </>
  )
}

export default Contact;