import address from '../../icons/address.svg'

const Address = () => {
  return(
    <>
      <div className='flex'>
          <img 
            src={address}
            className='footer-icon'
            alt='address'
          />
          <span>Address</span>
        </div>
        <span>Regus, 3/169 Fullarton Rd, Dulwich, SA, 5065</span>
    </>
  )
}

export default Address;