import './contact.scss'
import Form from '../../components/form/form'
import Footer from "../../components/footer/footer";
import { useNavigate } from 'react-router-dom';
import Home from '../../icons/home.svg'
import Logo from '../../images/smp_logo.png'

const Contact = () => {
  let navigate = useNavigate();

  const toHome = () => {
    navigate('/')
  }

  return(
    <>
    <div className='header-wrapper flex'>
      <div className="flex header">
        <img src={Logo} alt="logo" className="logo" onClick={toHome}/>
        <h1 className="title main-title">
          Susan McPhee Psychology
        </h1>
      </div>
      <div className='flex home-icon-wrapper' onClick={toHome}>
        <img src={Home} alt='home' className='icon home-icon'/>
        <p>Home</p>
      </div>
    </div>
    <hr className='contact-bar'></hr>
    <Form />
    <Footer />
    </>
  )
}

export default Contact;