import './main.scss';
import '../../styles.scss'
import AboutMe from '../../database/aboutMe.json';
import Client from '../../components/client/client';
import Footer from '../../components/footer/footer';
import Susan from '../../images/Susan.JPG'
import Title from '../../atoms/title/title';
import Logo from '../../images/smp_logo.png';
import { useState } from 'react';

const Main = () => {

  const [openReadMore, setOpenReadMore] = useState(false);

  return (
    <>
      <div className="flex main-header">
        <img src={Logo} alt="company_logo" className="logo" />
        <span className="title main-title">
          Susan McPhee Psychology
        </span>
      </div>
      <Title title='About Susan' />
      <div className='main-container'>
        <div className='about-me-body'>
          <div className='about-me-img-sub'>
            <img src={Susan} alt='Susan McPhee' className='about-me-img' />
            <div className='about-me-subtitle'>
              <p>Susan McPhee</p>
              <h5>Director</h5>
            </div>
          </div>
          {/* <div> */}
          <div className='about-me-content'>
            {AboutMe.map((m, i) => {
              // display the first two paragraphs
              if (i < 2) {
                return (
                  <p className='about-me-paragraph' key={i}>{m}</p>
                )
              } else {
                if (openReadMore) {
                  return (
                    <p className='about-me-paragraph' key={i}>{m}</p>
                  )
                }
              }
            })}
            <div className='read-more-container'>
              {!openReadMore && <button onClick={() => setOpenReadMore(true)} className='read-more'>Read More</button>}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* <Title title='Services' /> */}
      <Client />
      <Footer />
    </>
  )
}

export default Main;