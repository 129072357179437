import './form.scss'
import UserInput from '../../atoms/user_input/user_input';
import { useState, useEffect } from 'react';

const Form = () => {
  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [inputError, setInputError] = useState();
  const [sentEmail, setSentEmail] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (!inputError && inputError != undefined) {
      console.log("Call API")
      fetch(`/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input })
      }).then(res => {
        if (!res.ok) {
          console.log("response isn't okay")
        }
        if (res.status === 200) {
          setSentEmail(true)
          setSpinner(false)
          setInput({ name: "", email: "", phone: "", message: "" })
          setTimeout(() => {
            setSentEmail(false);
          }, 3000);
        }
      })
    }
  }, [inputError])

  const formFields = [
    {
      id: 1,
      name: 'name',
      type: 'text',
      label: 'Name',
      placeholder: 'Susan McPhee',
      // pattern: '(?:[a-zA-Z\\\']+[ \\-]?)+',
      // pattern: '[^!-&(-,/-@^_`~-¿]+',
      errorMessage: 'Please enter your name',
      required: true,
    },
    {
      id: 2,
      name: 'email',
      type: 'text',
      label: 'Email',
      placeholder: 'psychology@gmail.com',
      pattern: '^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)*[a-zA-Z0-9]+$',
      errorMessage: 'Please confirm your email',
      required: true,
    },
    {
      id: 3,
      name: 'phone',
      type: 'text',
      label: 'Phone',
      placeholder: '0123456789',
      pattern: '^[\\d\\+\\s\\-\\(\\)]{6,32}$',
      errorMessage: 'Please confirm your phone number'
    },
    // {
    //   id: 4,
    //   name: 'message',
    //   type: 'textarea',
    //   label: 'Message',
    //   placeholder: 'Please enter your query',
    //   errorMessage: 'Please enter a message',
    //   required: true,
    // }
  ]
  const msgTextarea =
  {
    id: 4,
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Please enter your query',
    errorMessage: 'Please enter a message',
    required: true,
  }

  const checkValidation = ({ name, email, phone, message }) => {
    let invalid = false;
    if (!name || !email || !message) {
      invalid = true;
    } else {
      formFields.map((f, i) => {
        let regExp = new RegExp(f.pattern);
        let checkRegExp;
        if (f.name === 'email') {
          checkRegExp = regExp.test(email);
          if (!checkRegExp) invalid = true;
        }
        else if (f.name === 'phone' && phone) {
          checkRegExp = regExp.test(phone)
          if (!checkRegExp) invalid = true;
        }
      })
    }
    if (invalid) setInputError(true)
    else {
      setInputError(false)
      setSpinner(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    checkValidation(input);
  }

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const cancelContact = () => {
    setInput({ name: "", email: "", phone: "", message: "" })
  }
  return (
    <div className='main-container'>
      <h3 className='form-title'>How can I support you?</h3>
      <h5 className='form-subtitle'>I will get back to you as soon as I can.</h5>
      <div className='form-wrapper center'>
        <form>
          <div className='name-email-phone-container'>
            {formFields.map(f => (
              <UserInput
                // className='form-user-input'
                key={f.id}
                {...f}
                value={input[f.name]}
                onChange={onChange}
                sentEmail={sentEmail}
              />
            ))}
          </div>
          <div className='message-container'>
            <UserInput
              key={msgTextarea.id}
              {...msgTextarea}
              value={input[msgTextarea.name]}
              onChange={onChange}
              sentEmail={sentEmail}
            />
          </div>
        </form>
        <div className='btn-container'>
          <button className='cancel-btn' onClick={cancelContact}>Cancel</button>
          {spinner ? <span className='spinner'></span>
            : <div className='submit-btn'>
              <button onClick={handleSubmit}>Submit</button>
            </div>}
        </div>
        {sentEmail && <p className='message'>Message has been sent!</p>}
        {inputError && <p className='error message'>Please confirm your input again.</p>}
      </div>
    </div>
  )
}

export default Form;