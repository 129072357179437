import React from 'react';
import Main from './pages/main/main';

import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Contact from './pages/contact/contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path='/contact' element={<Contact />}/>
      </Routes>
    </Router>
  );
}

export default App;
