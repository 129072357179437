import './clientType.scss'
import individual from '../../icons/individual.svg'
import organisation from '../../icons/organisation.svg'

const ClientType = ({ type }) => {
  return(
    <div className='client-type'>
      <img src={type === 'Individual' ? individual : organisation} alt={type} className='icon' />
      <span>{type}</span>
    </div>
  )
}

export default ClientType;