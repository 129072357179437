import './service.scss'
import individualsConsulting from '../../images/banner.png'
// import individualsConsulting from '../../images/consulting.jpg'
// import organisationsConsulting from '../../images/consulting.jpg'
import { useNavigate } from 'react-router-dom';

const Service = ({ type, contents, services, button }) => {
  let navigate = useNavigate();
  const booking = 'https://clientportal.powerdiary.com/clientportal/susanmcpheepsychology';

  const individual = () =>
  (
    window.open(booking)
  )

  const organisation = () => {
    navigate('/contact')
  }
  return (
    <>
      <div className='main-container'>
        <div>
          {contents.map((c, i) => (
            <p className='content' key={i}>{c}</p>
          ))}
        </div>
        <div className='services'>
          {services.map((s, i) => (
            <li key={i}>{s}</li>
          ))}
        </div>
        {type === "Individual" ? 
        <p className='content'>
          If you would like to <a href={booking} target="_blank">make an appointment </a> to see Susan please press the button below and it will take you to Susan’s Power Diary. If you have any questions please <a onClick={organisation} className='to-contact'>Contact</a> her and she will get back to you as soon as possible.
        </p> :
        <p className='content'>
          If you would like discuss ways that Susan can assist you and your organisation or if you have any questions, please <a onClick={organisation} className='to-contact'>Contact</a> her and she will get back to you as soon as possible.
        </p>
      }
          
        <div className='button-container'>
          <button
            type='button'
            onClick={type === 'Individual' ? individual : organisation}
            className={type === 'Individual' ? 'service-button individuals' : 'service-button organisations'}>
            {button}
          </button>
        </div>
      </div>
      <img
        src={type === 'Individual' ? individualsConsulting : undefined}
        alt={type === 'Individual' ? 'individual' : undefined}
        className={type === 'Individual' ? 'service-img' : undefined} />
    </>
  )
}

export default Service;