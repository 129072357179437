import './quote.scss'
import ClientType from '../clientType/clientType';

const Quote = ({ quote, author, type }) => {
  return(
  <div className='quote flex main-container'>
    <ClientType type={type}/>
    <div className={type ==='Individual' ? 'quote-box individual' : 'quote-box organisation'}>
      <p>{quote}</p>
      <p className='quote-author'>{author}</p>
    </div>
  </div>
  )
}

export default Quote;