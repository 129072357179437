import { useState, useEffect } from 'react';
import './footer.scss'
import myOffice from "../../images/myOffice.jpg";
import business_hour from '../../icons/business_hour.svg'
import Contact from '../../atoms/contact/contact';
import Address from '../../atoms/address/address';
import Logo from '../../images/smp_logo.png';
import MAAPi from '../../images/MAAPi-logo-red.png';
import LinkedIn from '../../images/linkedin-48.svg';
// import MAAPi from '../../images/MAAPi-logo-white.png'

const Footer = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleScreenSize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', handleScreenSize);

    return () => {
      window.removeEventListener('resize', handleScreenSize)
    }
  }, [])

  const changeDisplay = () => {
    // if(screenSize > 760 && screenSize < 1200){
    return (
      <div className='details-map'>
        <div className='details-flex'>
          <div>
            <div className='business-details'>
              <div className='flex'>
                <img
                  className='footer-icon'
                  src={business_hour}
                  alt='business hour'
                />
                <p>Business Hours</p>
              </div>
              <span>Depends on Appointments</span>
            </div>
            <Contact />
            <Address />
          </div>
        </div>
        <div className='google-map'>
          <a href='https://www.google.com/maps/place/Regus+-+Adelaide+Victoria+Park/@-34.9347795,138.6217689,17z/data=!3m1!4b1!4m6!3m5!1s0x6ab0ceb0d3d0e9fd:0x6877429d9d176c8!8m2!3d-34.9347839!4d138.6243438!16s%2Fg%2F1hc1l_w7c?entry=ttu' target='_target'>
            <img id='map-image' src={myOffice} alt='my office location' />
            <p>View on Google Maps</p>
          </a>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="footer-wrapper">
        <div className='footers'>
          <div className='footer'>
            <a href='/'>
              <img src={Logo} alt="logo" className="footer-logo" />
            </a>
            <h1 className='footer-title'>
              Susan McPhee Psychology
            </h1>
          </div>
          <h3 className='footer-subtitle'>I acknowledge that the land that I live and work on is the traditional land of the Kaurna people and pay respects to their people and culture.</h3>
          <div className='maapi-wrapper'>
            <img src={MAAPi} className='maapi-img-footer' />
            <a href='https://www.linkedin.com/in/susan-mcphee-76540517/' target='_blank'><img className='linkedIn' src={LinkedIn} alt='linkedin-link'></img></a>
          </div>
        </div>
        {changeDisplay()}
      </div>
      <h5 className='banner-provider'>Image by rawpixel.com on Freepik</h5>
    </>
  )
}

export default Footer;