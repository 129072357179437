import Quote from '../../atoms/quote/quote';
import Service from '../../atoms/services/service';
import Clients from '../../database/clients.json';
import Title from '../../atoms/title/title';

const Client = () => {
  return(
    <>
    <Title title='Services' />
    {
      Clients.map((q, i) => (
        <div key={i}>
          <Quote type={q.type} quote={q.quote} author={q.author}/>
          <Service type={q.type} contents={q.contents} services={q.services} button={q.button}/>
        </div>
      ))
    }
    </>
  )
}

export default Client;